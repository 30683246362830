// import $ from 'jquery';
// window.jQuery = $;
// window.$ = $;
// import './js/navigation.js'; 
// import Foundation from 'foundation-sites';

// import { Foundation } from './theme-critical'; // currently not using



var WebFont = require('webfontloader'); // Page base

import {
  Foundation
} from 'foundation-sites/js/foundation.core';
// import * as CoreUtils from 'foundation-sites/js/foundation.core.utils'; // not using - needed for utils ??????
// import { Box } from 'foundation-sites/js/foundation.util.box'; // Will return an object that contains the dimensions of element
import { onImagesLoaded } from 'foundation-sites/js/foundation.util.imageLoader'; // This will execute your callback function after all the images in your jQuery collection have loaded.
// import { Keyboard } from 'foundation-sites/js/foundation.util.keyboard'; // Methods for keyboard interaction 
import { MediaQuery } from 'foundation-sites/js/foundation.util.mediaQuery'; // The media query library used by Foundation has two publicly accessible functions and two properties
// import { Motion, Move } from 'foundation-sites/js/foundation.util.motion'; // Two utils
// import { Nest } from 'foundation-sites/js/foundation.util.nest'; ??????
// import { Timer } from 'foundation-sites/js/foundation.util.timer'; // Similar to setInterval, except you can pause and resume where you left off
// import { Touch } from 'foundation-sites/js/foundation.util.touch'; // Allows you to add swipe* and pseudo-drag events to elements
// import { Triggers } from 'foundation-sites/js/foundation.util.triggers'; // Provides a number of event listeners and triggers your script can hook into
// import { Abide } from 'foundation-sites/js/foundation.abide';
// import { Accordion } from 'foundation-sites/js/foundation.accordion';
import {
  AccordionMenu
} from 'foundation-sites/js/foundation.accordionMenu'; // Page base
// import { Drilldown } from 'foundation-sites/js/foundation.drilldown'; // Page base
// import { Dropdown } from 'foundation-sites/js/foundation.dropdown';
import {
  DropdownMenu
} from 'foundation-sites/js/foundation.dropdownMenu'; // Page base
import { Equalizer } from 'foundation-sites/js/foundation.equalizer';
// import { Interchange } from 'foundation-sites/js/foundation.interchange';
// import { Magellan } from 'foundation-sites/js/foundation.magellan';
import {
  OffCanvas
} from 'foundation-sites/js/foundation.offcanvas'; // Page base
// import { Orbit } from 'foundation-sites/js/foundation.orbit';
import {
  ResponsiveMenu
} from 'foundation-sites/js/foundation.responsiveMenu'; // Page base
// import { ResponsiveToggle } from 'foundation-sites/js/foundation.responsiveToggle'; // _jwp_nav-title-bar.php
// import { Reveal } from 'foundation-sites/js/foundation.reveal';
// import { Slider } from 'foundation-sites/js/foundation.slider';
// import { SmoothScroll } from 'foundation-sites/js/foundation.smoothScroll';
// import { Sticky } from 'foundation-sites/js/foundation.sticky';
// import { Tabs } from 'foundation-sites/js/foundation.tabs';
// import { Toggler } from 'foundation-sites/js/foundation.toggler';
// import { Tooltip } from 'foundation-sites/js/foundation.tooltip';
// import { ResponsiveAccordionTabs } from 'foundation-sites/js/foundation.responsiveAccordionTabs';

Foundation.addToJquery($);
// test adding a foundation component to jQuery.

// Add Foundation Utils to Foundation global namespace for backwards
// compatibility.

// *** Not using - needed for utils ??????
// Foundation.rtl = CoreUtils.rtl;
// Foundation.GetYoDigits = CoreUtils.GetYoDigits;
// Foundation.transitionend = CoreUtils.transitionend;
// Foundation.RegExpEscape = CoreUtils.RegExpEscape;
// Foundation.onLoad = CoreUtils.onLoad;

// *** Not using - needed for utils ??????
// Foundation.Box = Box;
Foundation.onImagesLoaded = onImagesLoaded;
// Foundation.Keyboard = Keyboard;
Foundation.MediaQuery = MediaQuery;
// Foundation.Motion = Motion;
// Foundation.Move = Move;
// Foundation.Nest = Nest;
// Foundation.Timer = Timer;

// Touch and Triggers previously were almost purely sede effect driven,
// so no need to add it to Foundation, just init them.

// *** Not using - needed for utils ??????
// Touch.init($);
// Triggers.init($, Foundation);
MediaQuery._init();

// Foundation.plugin(Abide, 'Abide');
// Foundation.plugin(Accordion, 'Accordion');
Foundation.plugin(AccordionMenu, 'AccordionMenu'); // Page base
// Foundation.plugin(Drilldown, 'Drilldown'); // Page base
// Foundation.plugin(Dropdown, 'Dropdown');
Foundation.plugin(DropdownMenu, 'DropdownMenu'); // Page base
Foundation.plugin(Equalizer, 'Equalizer');
// Foundation.plugin(Interchange, 'Interchange');
// Foundation.plugin(Magellan, 'Magellan');
Foundation.plugin(OffCanvas, 'OffCanvas'); // Page base
// Foundation.plugin(Orbit, 'Orbit');
Foundation.plugin(ResponsiveMenu, 'ResponsiveMenu'); // Page base
// Foundation.plugin(ResponsiveToggle, 'ResponsiveToggle');
// Foundation.plugin(Reveal, 'Reveal');
// Foundation.plugin(Slider, 'Slider');
// Foundation.plugin(SmoothScroll, 'SmoothScroll');
// Foundation.plugin(Sticky, 'Sticky');
// Foundation.plugin(Tabs, 'Tabs');
// Foundation.plugin(Toggler, 'Toggler');
// Foundation.plugin(Tooltip, 'Tooltip');
// Foundation.plugin(ResponsiveAccordionTabs, 'ResponsiveAccordionTabs');

// export { Foundation };


// import {
//     initializer
// } from './js/foundation';
// require('waypoints/lib/noframework.waypoints.min');
// import 'slick-carousel';
// import 'slick-carousel/slick/slick.css';
// import './styles/critical.scss';
import './theme.scss';

import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles

// initializer.key_initFoundation();
// jQuery(document).foundation();
// cannot apply update. Need to do a full reload - only accept if full relod is not needed
// if(module && module.hot){
//     module.hot.accept();
// }   
// ****************************** // 
// ****** WRAPPER  ****** // 
// ****************************** // 
// IIFE - Immediately Invoked Function Expression
(function (bptcode) {

  // The global jQuery object is passed as a parameter 
  bptcode(window.jQuery, window, document);

}(function ($, window, document) {

  // The $ is now locally scoped  
  $(document).ready(function () {
    // *** CODE HERE *** //
    $(document).foundation();

    WebFont.load({
      custom: {
        families: ['Frank Ruhl Libre', 'Albert Sans'],
      }
    });
    
    setTimeout(function () {
      Foundation.reflow('equalizer');
      AOS.init({
        // Global settings:
        disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
        startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
        initClassName: 'aos-init', // class applied after initialization
        animatedClassName: 'aos-animate', // class applied on animation
        useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
        disableMutationObserver: false, // disables automatic mutations' detections (advanced)
        debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
        throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)
        
        // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
        offset: 200, // offset (in px) from the original trigger point
        delay: 0, // values from 0 to 3000, with step 50ms
        duration: 750, // values from 0 to 3000, with step 50ms
        easing: 'ease', // default easing for AOS animations
        once: true, // whether animation should happen only once - while scrolling down
        mirror: false, // whether elements should animate out while scrolling past them
        anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
      });
    }, 250);


    $.fn.isInViewport = function () {
      var elementTop = $(this).offset().top;
      var elementBottom = elementTop + $(this).outerHeight();
      var viewportTop = $(window).scrollTop();
      var viewportBottom = viewportTop + $(window).height();
      return elementBottom > viewportTop && elementTop < viewportBottom;
    };
    function elements() {

      if ($('.content-image').length > 0) {
        if ($('.content-image').isInViewport()) {
          $('.content-image__wrap').addClass('is-active');
        } else {
          $('.content-image__wrap').removeClass('is-active');
        }
      }

      if ($('.cta-split-one').length > 0) {
        if ($('.cta-split-one').isInViewport()) {
          $('.cta-split-one').addClass('is-active');
        } else {
          $('.cta-split-one').removeClass('is-active');
        }
      }

      if ($('.cta-split-two').length > 0) {
        if ($('.cta-split-two').isInViewport()) {
          $('.cta-split-two').addClass('is-active');
        } else {
          $('.cta-split-two').removeClass('is-active');
        }
      }
      if ($('.hero-sh-content-cta').length > 0) {
 
        if ($('.hero-sh-content-cta').isInViewport()) {
          console.log('ready');
          $('.hero-sh-content-cta').addClass('is-active');
        } else {
          $('.hero-sh-content-cta').removeClass('is-active');
        }
      }
      if ($('.slider-content-image').length > 0) {
 
        if ($('.slider-content-image').isInViewport()) {
          console.log('ready');
          $('.slider-content-image__item.slick-active .slider-content-image__wrap').addClass('is-active');
        } else {
          $('.slider-content-image__item.slick-active .slider-content-image__wrap').removeClass('is-active');
        }
      }
    }
    $(window).on('resize scroll', function () {
      elements();
    });
    setTimeout(function(){
      elements();
    },250);

    $('.no-fouc').removeClass('no-fouc');


    // *** END CODE *** //
    // ****************************** // 
    // ****** END WRAPPER  ****** // 
    // ****************************** //
  });
}));